import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Taurus.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Taurus Traits
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/taurus"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Taurus</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Taurus Traits</h4>
              
             
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Taurus Traits </h1>
                <h2 className="text-sm md:text-base ml-4">Apr 20 - May 20</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Taurus Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Taurus Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Taurus Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-love");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-nature");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-man");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-traits");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-facts");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            
            <p className="playfair text-black text-xl md:text-3xl">About Taurus</p><br/>
The astrological sign of Taurus is represented by the bull, a stubborn and determined creature. People born under the Taurus zodiac sign are known for their dependability, loyalty, and stubborn nature. Taurus is known for being an honest and hardworking individual. Taurus is also known for being materialistic and possessive.
<br/><br/>
You are born with specific natural strengths and weaknesses. Your strengths are the things you are naturally good at and excel at without much effort. On the other hand, your weaknesses are the things you aren't inherently good at and don't excel at without much effort. Together, your strengths and weaknesses determine your ability level and your areas of interest and expertise.
<br/><br/>
Taurus is one of the most dependable signs of the zodiac and, as such, is known for its reliability, determination, and loyalty. You are a passionate person, and your instinct is to keep to your values and mission. This makes you a great partner, as you will always have your partner's back. Even when you are wrong, you will never abandon your beliefs.
 <br/><br/>
We all have strengths and weaknesses, and they can both be used to our best advantage once we better understand them.
<br/><br/>
<p className="font-bold inline-block text-orange-500">Positive traits:</p> Reliable, generous and trustworthy, determined, understanding, enthusiastic, organized, patient.
<br/><br/>
<p className="font-bold inline-block text-orange-500">Negative traits:</p> Jealous, lazy, stubborn, dependant, imbalanced materialistic, possessive
<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Taurus positive traits</p><br/>
Taurus personality traits can be confusing, as this sign is a mix of cardinal and fixed signs. This makes them both strong and vulnerable and determined and indecisive. However, they're also naturally stable, reliable, and independent. This makes them great partners, as they're always there for you when you need them.
 <br/><br/>
 <p className="font-bold inline-block text-orange-500">Negative traits:</p>
You are very generous with the people around you. You are loyal individuals who love to maintain relationships till the end after you earn trust from them. You don't like to be the first to cut friends and family members out of your life. You are always there to listen and lend an ear when someone needs you.
<br/><br/>
<p className="font-bold inline-block text-orange-500">Loyal:</p> 
You are loyal to those you trust and are generous with those you care about. You'll go out of your way to help those you like, and you tend to form close, personal bonds with others. Your loyalty knows no bounds, and you are always willing to help your friends and family in need. However, you need to build a solid foundation of trust with those around you before you open up too much.
 <br/><br/>
 <p className="font-bold inline-block text-orange-500">Determined:</p> 
When you think of a Taurus, you likely think of someone stubborn and determined. These qualities are a big part of who a Taurus is, but they don't tell the whole story. A Taurus' nature is also determined, and this is something that they recognize in themselves. They will decide and stick with it, even when others try to convince them to do otherwise.
<br/><br/>
<p className="font-bold inline-block text-orange-500">Understanding:</p>
Taurus understands that the best way to get what they want is to be persistent and keep working toward their goal until they get it. They are a hard worker and tend to be very good at whatever they put their mind to. They don't give up so quickly and will pursue their goals with determination. They are patient and tend to think things through before making decisions.
 <br/><br/>

Enthusiastic
Taurus is an enthusiastic, warm, and affectionate sign, making them seem like a people person even when they're not. They need to learn to rein in their impulses and listen to what others are saying because they often jump to conclusions and miss important details. They also need to remember that just because someone is driven to achieve a goal doesn't mean they don't need or deserve recognition for their hard work.
 <br/><br/>

 <p className="font-bold inline-block text-orange-500">Organized:</p>
You are a naturally well-organized person, and your plan for the day starts long before the sun rises. You like to have everything correctly, and your home and workspace reflect this. You are a great list-maker and can usually find the time and energy to finish any job or task. Your practical nature makes you a great planner and organizer, but it can make you a little more stubborn and less patient than you might like.
 <br/><br/>

 <p className="font-bold inline-block text-orange-500">Patient:</p> 
Your sign is associated with patient, steady, committed, and reliable people. It is said that those born under the influence of Taurus are slow to anger, tend to be clingy, and are generally quite laid-back and easy-going. While Taurus is a fixed sign, it has a solid connection to the element of earth. Its natural tendency is to be grounded, cautious, and secure. This means that Taurus individuals will generally have a great deal of difficulty with change and will have trouble deciding what to do.
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Taurus negative traits</p><br/>

<p className="font-bold inline-block text-orange-500"></p>Jealous: 
When a loved one is close to someone else, you may feel jealous and insecure. Taurus-style jealousy is all about how you think, not the other person's actions. Your jealousy might make you feel like you can't trust your partner. It also might lead you to do things that you later regret, like spying on your partner or confronting them about their relationship.
 <br/><br/>

 <p className="font-bold inline-block text-orange-500">Lazy:</p> 
When you think of Taurus, the first thing that probably comes to mind is being lazy. It's true. Taureans are known for being rather sluggish at times. But it's not their fault! They were just born to be lethargic, which means that they have difficulty getting things done.
<br/><br/>
<p className="font-bold inline-block text-orange-500">Stubborn: </p>
Taureans are stubborn and don't like to take no for an answer. They are determined to get what they want, whether working a little more complicated, waiting a little longer, or just being persistent. They tend to over-schedule themselves, sometimes to the point of exhaustion. But when they set their mind to something, nothing can stop them.
 <br/><br/>
 <p className="font-bold inline-block text-orange-500">Dependent:</p>
You, the Dependence Partner, in a relationship context. Taurus is the leader of the Dependence trio, which means they're the most dependent on their partners. They're the one who needs their partner the most and the one who suffers the most when they're not around. When Taurus is single, they often feel like a part of them is missing.
<br/><br/>
Possessive
They can be highly possessive, often becoming jealous of the attention you give to others. They don't like to share with you and can be very territorial. When they say they love you, they mean it. They're not afraid to show their affection in public, and they don't care who's watching when they tell you how much they love you.
<br/><br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
